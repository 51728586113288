import React from "react";
import Card from "@material-ui/core/Card";
import { CardContent, makeStyles, Typography } from "@material-ui/core";
import useWindowDimensions from "../../utils/windowsDimensions";
import { useMediaQuery } from "react-responsive";

function MonitorCrad(props: any) {
  const { isDarkMode, status, description, marginLeft } = props;
  const { height, width } = useWindowDimensions();
  const classes = useStyles();
  const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isIpadPro = useMediaQuery({ query: "(min-device-width: 1024px)" });
  const isTablet = useMediaQuery({
    query: "(min-device-width: 961px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-device-width: 480px)",
  });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  return (
    <Card
      className={classes.root}
      style={{
        backgroundColor: !isDarkMode ? "#C0D0DD" : "#86AC5F",
        borderRadius: isDesktop ? 15 : 10,
        width: isDesktop ? width * 0.13 : width * 0.13,
        height: isDesktop ? width * 0.07 : width * 0.08,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: isDesktop ? 10 : 0,
        marginLeft: marginLeft,
      }}
    >
      <CardContent>
        <Typography color="textSecondary" style={{ fontSize: "1vw" }}>
          {status}
        </Typography>
        <Typography
          color="textSecondary"
          style={{ fontWeight: "bolder", fontSize: "2.5vw" }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}
export default MonitorCrad;

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));
