import React from "react";
import Typography from "@material-ui/core/Typography";
import { Avatar, Button, Card, CardContent, Grid } from "@material-ui/core";
import ErrorList from "./ErrorList";
import avatar from "../../assets/avatar.png";
import useWindowDimensions from "../../utils/windowsDimensions";
import AlaskaLogoDark from "../../assets/Alaska-Airlines-Logo-w.png";
import AlaskaLogo from "../../assets/Alaska-Airlines-Logo-b.png";
import { makeStyles } from "@material-ui/core/styles";

function RightSideBar(props: any) {
  const { children, value, index, isDarkMode, ...other } = props;
  const { height, width } = useWindowDimensions();
  const useStyles = makeStyles((theme) => ({
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },

    large: {
      width: 100,
      height: 100,
    },
  }));
  const classes = useStyles();
  return (
    <Grid md={3} container direction="column" style={{ height: height }}>
      <div style={{ padding: 30 }}>
        <img
          src={isDarkMode ? AlaskaLogo : AlaskaLogoDark}
          style={{
            width: width * 0.13,
            alignSelf: "flex-start",
            marginBottom: 70,
          }}
          alt="logo"
        />
        <Grid container direction="column" justifyContent="space-around">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ flexDirection: "column" }}>
              <Typography style={{ fontSize: 30, fontWeight: "lighter" }}>
                Hello!
              </Typography>
              <Typography style={{ fontSize: 42, fontWeight: "bold" }}>
                Shima
              </Typography>
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: "lighter",
                  opacity: 0.8,
                }}
              >
                {"Good to see you again!"}
                {" Your last login and update info:"}
              </Typography>
            </div>

            <Avatar
              style={{ marginTop: 55 }}
              src={avatar}
              className={classes.large}
            />
          </div>
        </Grid>
        <Card
          className={classes.root}
          style={{
            backgroundColor: !isDarkMode ? "#83A3BC" : "#86AC5F",
            marginTop: 40,
            borderRadius: 25,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
          }}
        >
          <CardContent>
            <Typography
              style={{
                fontWeight: "lighter",
                color: "white",
                fontSize: 16,
              }}
            >
              Last Update
            </Typography>
            <Typography
              style={{
                fontWeight: "bolder",
                color: "white",
                fontSize: "1.3vw",
                marginBottom: 10,
                paddingLeft: 30,
              }}
            >
              10 minutes ago
            </Typography>
            <Typography
              style={{
                fontWeight: "lighter",
                color: "white",
                fontSize: 16,
              }}
            >
              Last Login
            </Typography>
            <Typography
              style={{
                fontWeight: "bolder",
                color: "white",
                fontSize: "1.3vw",
                paddingLeft: 30,
              }}
            >
              2 days ago
            </Typography>
          </CardContent>
        </Card>
        <div
          style={{
            marginTop: 40,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 15,
          }}
        >
          <div style={{}}>
            <Typography
              style={{ fontWeight: "bold", fontSize: 26 }}
              gutterBottom
            >
              Alerts
            </Typography>
          </div>
          <Button
            style={{
              alignSelf: "flex-end",
              color: isDarkMode ? "white" : "#2687D4",
              fontSize: 16,
              textTransform: "capitalize",
            }}
          >
            View All
          </Button>
        </div>
        {errorListData.map((name, index) => (
          <ErrorList
            isDarkMode={isDarkMode}
            time={name.time}
            description={name.description}
          />
        ))}
      </div>
    </Grid>
  );
}
export default RightSideBar;
const errorListData = [
  {
    time: " 2021-07-25 9:47 AM",
    description: "Collector - Publisher ICD Version Mismatch",
  },
  {
    time: "2020-03-18 9:47 PM",
    description: "Unable to start Collector",
  },
  {
    time: "2019-05-07 11:58 AM",
    description: "Internet Connection Loss",
  },
];
