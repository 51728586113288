import React from 'react';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { Grid } from '@material-ui/core';


function ErrorList(props: any) {
    const { children, value, index,isDarkMode,time, description, ...other } = props;
  
    return (
        <Grid
        container
        direction="row"
        style={{ paddingBottom: 25 }}
        alignItems="center"
      >
        <ErrorOutlineOutlinedIcon
        style={{
          color: isDarkMode ? "white" : "#064778",
          marginRight: 15,
          fontSize: 55,
          opacity: 0.5,
        }}
      />
      <div>
        <Typography
          gutterBottom
          style={{ fontWeight: "bold", fontSize: 18 }}
        >
         {time}
        </Typography>
        <Typography style={{ opacity: 0.5, fontSize: 14 }}>
         {description}
        </Typography>
      </div>
      </Grid>
    );
  }
 export default ErrorList;