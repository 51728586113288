import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Autocomplete } from "@material-ui/lab";
import EnhancedTable from "./MonitoringTable";
import { Chip, Grid, makeStyles, TextField } from "@material-ui/core";
import useWindowDimensions from "../../utils/windowsDimensions";

function TabPanel(props: any) {
  interface Data {
    value: number;
    time: string;
    date: string;
    name: string;
  }

  function createData(
    name: string,
    value: number,
    date: string,
    time: string
  ): Data {
    return { name, value, date, time };
  }
  const { children, value, index, isDesktop, isDarkMode,timestamp, model,rowData, ...other } = props;
  const { height, width } = useWindowDimensions();
  const useStyles = makeStyles((theme) => ({
    paper: {
      border: "1px grey",
      backgroundColor: isDarkMode ? "#223754" : "white",
    },
  }));
  const rows = [
    createData("HDG_TRUE", 30.74, "2021-07-15", "12:05:00 AM"),
    createData("CAS", 127.191, "2021-07-15", "12:04:00 AM"),
    createData("PITCH", 3.724, "2021-07-15", "12:03:00 AM"),
    createData("ALT_MSL", 582.1107, "2021-07-15", "12:02:00 AM"),
    createData("ROLL", -0.72078, "2021-07-15", "12:01:00 AM"),
    createData("WOW_ANY", 0, "2021-07-15", "12:00:00 AM"),
    createData("ROC", -387.8, "2021-07-14", "11:59:59 PM"),
  ];

  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            style={{
              marginBottom: isDesktop ? 35 : 35,
              paddingLeft: 52,
              paddingRight: 52,
            }}
          >
            <div
              style={{
                paddingRight: 52,

                justifyContent: "flex-start",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div>
                <Typography
                  style={{
                    fontWeight: "lighter",
                    marginBottom: 3,
                    fontSize: isDesktop ? 14 : 12,
                  }}
                >
                  Equipment
                </Typography>
                <Typography
                  style={{
                    fontWeight: "lighter",
                    marginBottom: 10,
                    fontSize: isDesktop ? 19 : 14,
                  }}
                >
                 {model}
                </Typography>
              </div>
              <div style={{ marginLeft: 290 }}>
                <Typography
                  style={{
                    fontWeight: "lighter",
                    marginBottom: 3,
                    fontSize: isDesktop ? 14 : 12,
                  }}
                >
                  Last Frame
                </Typography>
                <Typography
                  style={{
                    fontWeight: "lighter",
                    marginBottom: 3,
                    fontSize: isDesktop ? 19 : 14,
                  }}
                >
                 {timestamp}
                </Typography>
              </div>
            </div>
            <div style={{ marginBottom: !isDesktop ? 30 : 0 }}>
              <Autocomplete
                options={rows}
                classes={{ paper: classes.paper }}
                getOptionLabel={(option) => option.name}
                style={{ width: 250 }}
                multiple
                id="tags-filled"
                freeSolo
                renderTags={(value: any[], getTagProps) =>
                  value.map((option: any, index: any) => (
                    <Chip
                      variant="outlined"
                      label={option.name}
                      {...getTagProps({ index })}
                      style={{ borderColor: "#C0D0DD" }}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // variant="outlined"
                    label="filter"
                    // placeholder=""
                    style={{ borderRadius: 25, height: 25 }}
                  />
                )}
              />
            </div>
          </Grid>

          <div
            style={{
              height: height * 0.1,

              paddingLeft: 52,
              paddingRight: 50,
            }}
          >
            <EnhancedTable />
          </div>
        </Box>
      )}
    </div>
  );
}
export default TabPanel;
