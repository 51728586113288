import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { DarkModeSwitch } from "react-toggle-dark-mode";


function DarkModeButton(props: any) {
    const { children, value, index,isDarkMode,onChange, ...other } = props;
  
    return (
        <DarkModeSwitch
        style={{
          justifyContent: "flex-end",
          marginRight: 30,
          marginTop: 0,
        }}
        checked={isDarkMode}
        onChange={onChange}
        size={35}
        moonColor="white"
        sunColor="#083E84"
      />
    );
  }
 export default DarkModeButton;