import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import Login from './auth/components/Login';
import Home from './monitoring/MainPage'
import {
  mainPage,
  login,
} from './utils/routes';


function App() {
  const accessToken = localStorage.getItem('Access-Token')

  return (
    <Router>
    <Wrapper>

      <Switch>
    <Route exact path={login()} component={Login} />
        {accessToken  && <Route exact path={'/mainPage'} component={Home} /> }
       
      </Switch>
    </Wrapper>
  </Router>
  );
}
const Wrapper = styled.div`
  background-color: #ffffff;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100vh;

  ::-webkit-scrollbar {
    width: 12px; /* for vertical scrollbars */
    height: 12px; /* for horizontal scrollbars */
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    background: #f7d747;
  }
`;
export default App;
