import * as React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import mainLogo from "../../assets/lightModeLogo.png";
import mainLogo1 from "../../assets/darkModeLogin.png";
import AlaskaDrak from "../../assets/AlaskaAirlinesLogo.png";
import Grid from "@material-ui/core/Grid";
import useWindowDimensions from "../../utils/windowsDimensions";
import Alaska from "../../assets/Alaska-Airlines-Logo.png";
import { TextField } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ThemeProvider,
  withStyles,
  makeStyles,
  createTheme,
} from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import useLocalStorage from "react-use-localstorage";
import axios from "axios";

const url =
  "https://development.rest-api.data-monitoring.pai-dev.io/login";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  lightMode: {
    backgroundColor: "#fff",
    color: "#1a1919",
    transition: "backgroundColor 0.2s ease",
  },
  darkMode: {
    backgroundColor: "#0A2241",
    color: "#fff",
  },
});

const Login = () => {
  //   if(matched)
  //     {console.log('Currently in dark mode');
  //     setDarkMode(true)}
  // else
  //     {console.log('Currently not in dark mode');
  //     setDarkMode(false)}

  const [isDarkMode, setDarkMode] = React.useState(false);
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isDarkModeStorage, setDarkModeStorage] = useLocalStorage("isDarkMode");
  const [message, setMessage] = React.useState("");
  const [isResetPass, setIsResetPass] = React.useState(false);
  const [accessTokens,setAccessToken] = useLocalStorage("Access-Token");

  const theme = createTheme({
    palette: {
      type: isDarkMode ? "dark" : "light",
    },
  });

  const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });
  const isIpadPro = useMediaQuery({ query: "(min-device-width: 1024px)" });
  const isTablet = useMediaQuery({
    query: "(min-device-width: 961px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-device-width: 480px)",
  });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const toggleDarkMode = (checked: boolean) => {
    setDarkMode(checked);
    setDarkModeStorage(JSON.stringify(checked));
  };
  const loginHandler = () => {
    // history.push("/mainPage");
    if ((userName.trim() === "" || password.trim() === "")) {
      setMessage("All fiels needs to be provided");
      return;
    }
    setMessage("");
    const requestConfig = {
      headers: {
        "x-api-key": "quu%$aq+VrE<,AvcT?*)bCh2<%#FR68wV-g'",

      },
    };
    const requestBody = {
      username: userName,
      password: password,
    };
    axios
      .post(
        "https://development.rest-api.data-monitoring.pai-dev.io/login",
        requestBody,
        requestConfig
      )
      .then((response) => {
        setMessage("signed Up");
        let accessToken = response.data.AuthenticationResult['AccessToken']
        // console.log(accessToken)
        setAccessToken(JSON.stringify(accessToken))
        if (accessToken){
          history.push("/mainPage")
        }
      })
      .catch((error) => {
        // console.log("error  :   ", error);
      });
  };

  const history = useHistory();
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  let Title =
    "A software system use to monitor \n the \n  data stream collected by\n Paladin AI's collector computers. ";
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <body
        className={isDarkMode ? classes.darkMode : classes.lightMode}
        style={{ flex: 1 }}
      >
        <Grid container spacing={0}>
          <Grid
            container
            xs={12}
            md={isIpadPro && isPortrait ? 12 : 6}
            sm={12}
            alignItems="center"
            justify="space-between"
            spacing={0}
            style={{}}
          >
            <div />
            <div>
              <DarkModeSwitch
                style={{
                  justifyContent: "flex-end",
                  marginRight: 30,
                  marginTop: !isDesktop ? 30 : 0,
                }}
                checked={isDarkMode}
                onChange={toggleDarkMode}
                size={35}
                moonColor="white"
                sunColor="#083E84"
              />
            </div>
            <Grid container direction="row" justify="center" spacing={0}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: isDesktop
                    ? 0.25 * width
                    : isPortrait
                    ? 0.85 * width
                    : isTablet
                    ? 0.35 * width
                    : isMobile
                    ? 0.85 * width
                    : 0.45 * width,
                  minWidth: isDesktop ? 0.15 * width : 0.15 * width,
                  justifyContent: "center",
                }}
              >
                <Grid container justify="center" spacing={0}>
                  <img
                    src={isDarkMode ? AlaskaDrak : Alaska}
                    style={{
                      width: "50%",
                      marginTop: isMobile ? width * 0.1 : 0,
                    }}
                    alt="logo"
                  />
                </Grid>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    style={{
                      padding: 5,
                      fontSize: 25,
                      marginTop: 35,
                      fontWeight: "lighter",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      opacity: 0.7,
                      textAlign: "center",
                      marginBottom: isMobile ? width * 0.1 : 0,
                    }}
                    justify="center"
                  >
                    InstructIQ Data Monitoring
                  </Grid>
                  {!isMobile ? (
                    <Grid
                      item
                      style={{
                        padding: 12,
                        fontFamily: "Arial, Helvetica, sans-serif",
                        fontSize: 14,
                        fontWeight: "lighter",
                        textAlign: "center",
                        lineHeight: 1.7,
                        marginBottom: 20,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                      justify="center"
                    >
                      {Title}
                    </Grid>
                  ) : null}
                </div>
                {!isResetPass && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: isDesktop
                        ? 0.25 * width
                        : isPortrait
                        ? 0.85 * width
                        : isTablet
                        ? 0.35 * width
                        : isMobile
                        ? 0.85 * width
                        : 0.45 * width,
                      minWidth: isDesktop ? 0.15 * width : 0.15 * width,
                      justifyContent: "center",
                    }}
                  >
                    <CssTextField
                      id="custom-css-outlined-input"
                      label="Usename"
                      margin="normal"
                      variant="outlined"
                      onChange={(event) => setUserName(event.target.value)}
                      value={userName}
                    />
                    <CssTextField
                      id="custom-css-outlined-input"
                      label="Password"
                      type="password"
                      margin="normal"
                      variant="outlined"
                      onChange={(event) => setPassword(event.target.value)}
                      value={password}
                    />
                  </div>
                )}
                {/* {isResetPass && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: isDesktop
                        ? 0.25 * width
                        : isPortrait
                        ? 0.85 * width
                        : isTablet
                        ? 0.35 * width
                        : isMobile
                        ? 0.85 * width
                        : 0.45 * width,
                      minWidth: isDesktop ? 0.15 * width : 0.15 * width,
                      justifyContent: "center",
                    }}
                  >
                    <CssTextField
                      id="custom-css-outlined-input"
                      label="Previous Password"
                      margin="normal"
                      variant="outlined"
                      onChange={(event) => setUserName(event.target.value)}
                      value={userName}
                    />
                    <CssTextField
                      id="custom-css-outlined-input"
                      label="New Password"
                      type="password"
                      margin="normal"
                      variant="outlined"
                      onChange={(event) => setPassword(event.target.value)}
                      value={password}
                    />
                    <CssTextField
                      id="custom-css-outlined-input"
                      label="Confirm Password"
                      type="password"
                      margin="normal"
                      variant="outlined"
                      onChange={(event) => setPassword(event.target.value)}
                      value={password}
                    />
                  </div>
                )} */}
                <Button
                  className={classes.root}
                  style={{
                    alignSelf: "flex-end",
                    color: isDarkMode ? "white" : "#2687D4",
                    fontSize: 14,
                    paddingTop: 5,
                    textTransform: "capitalize",
                    fontWeight: "lighter",
                  }}
                >
                  Forgot password?
                </Button>
                <div style={{ height: 20 }} />
                <Button
                  variant="contained"
                  style={{
                    maxHeight: "80px",
                    minHeight: "60px",
                    backgroundColor: !isDarkMode ? "#083E84" : "#86AC5F",
                    color: "white",
                    fontSize: 16,
                    textTransform: "capitalize",
                    fontWeight: "lighter",
                  }}
                  onClick={loginHandler}
                >
                  Sign In
                </Button>
                <Button
                  className={classes.root}
                  style={{
                    textTransform: "initial",
                    alignSelf: "flex-start",
                    fontSize: 14,
                    marginTop: 15,
                    fontWeight: "lighter",
                    color: isDarkMode ? "white" : "#1a1919",
                  }}
                >
                  To request an account email us
                </Button>
                {message && (
                  <p
                    style={{
                      textTransform: "initial",
                      alignSelf: "flex-start",
                      fontSize: 14,
                      marginTop: 10,
                      fontWeight: "lighter",
                      color: "red",
                      marginLeft: 8,
                    }}
                  >
                    {message}
                  </p>
                )}
                <div style={{ height: 20 }} />
              </div>
            </Grid>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              style={{
                fontFamily: "Arial, Helvetica, sans-serif",
                fontWeight: "lighter",
                opacity: 0.6,
                fontSize: 14,
                textAlign: "center",
                marginTop: !isDesktop ? 30 : 0,
                marginBottom: 20,
              }}
            >
              2021 PaladinAI, All right reserved.
            </Grid>
            <div />
          </Grid>
          {isDesktop || (isTablet && !isPortrait) ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              spacing={0}
              style={{ height: height }}
            >
              <img
                src={isDarkMode ? mainLogo1 : mainLogo}
                style={{
                  width: "100%",
                  height: height,
                  objectFit: "fill",
                }}
                alt=""
              />
            </Grid>
          ) : null}
        </Grid>
      </body>
    </ThemeProvider>
  );
};
export default Login;

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "grey",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "grey",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "lightgrey",
      },
      "&:hover fieldset": {
        borderColor: "lightgrey",
      },
      "&.Mui-focused fieldset": {
        borderColor: "lightgrey",
      },
    },
  },
})(TextField);
