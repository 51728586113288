import React, { useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Tabs,
  Tab,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ThemeProvider,
  makeStyles,
  createTheme,
} from "@material-ui/core/styles";
import clsx from "clsx";
import useWindowDimensions from "../utils/windowsDimensions";
import TabPanel from "./components/TabPanel";
import MenuIcon from "@material-ui/icons/Menu";
import DarkModeSwitch from "./components/DarkModeButton";
import { useMediaQuery } from "react-responsive";
import MonitorCrad from "./components/MonitorCard";
import { Shadows } from "@material-ui/core/styles/shadows";
import RightSideBar from "./components/RightSideBar";
import LeftSideDrewer from "./components/LeftSideDrewer";
import socketIOClient, { Socket } from "socket.io-client";
import { useSelector } from "react-redux";
import { DarkmodeActions } from "../auth/types";
const ENDPOINT = "wss://3hm8z2e2m9.execute-api.us-east-1.amazonaws.com/latest";

const MainPage = () => {
  const socket = useRef<WebSocket | null>(null);
  const [isDarkMode, setDarkMode] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [isDrawerClicked, setIsDrawerClicked] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isConnected, setIsConnected] = React.useState(false);
  const [response, setResponse] = React.useState([]);
  // const state = useSelector<DarkmodeActions>(state => state.isDarkMode)
  // dark-mode media query matched or not
  let matched = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const onSocketOpen = useCallback(() => {
    setIsConnected(true);
  }, []);
  const onSocketClose = useCallback(() => {
    setIsConnected(false);
  }, []);
  useEffect(() => {
    if (socket.current?.readyState !== WebSocket.OPEN) {
      socket.current = new WebSocket(ENDPOINT);
      socket.current.addEventListener("open", onSocketOpen);
      socket.current.addEventListener("close", onSocketClose);
    }
    // const socket = socketIOClient(ENDPOINT);
    // socket.on("FromAPI", data => {
    //   console.log("response1",data)
    //   setResponse(data);
    // });
  }, []);

  // if(matched)
  //     {console.log('Currently in dark mode');
  //     setDarkMode(true)}
  // else
  //     {console.log('Currently not in dark mode');
  //     setDarkMode(false)}
  const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });
  const useStyles = makeStyles((theme) => ({
    lightMode: {
      backgroundColor: "#fff",
      color: "#1a1919",
      transition: "backgroundColor 0.2s ease",
    },
    darkMode: {
      backgroundColor: "#0A2241",
      color: "#fff",
    },

    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },

    content: {
      flexGrow: 1,
    },
  }));

  const classes = useStyles();
  const theme = createTheme({
    overrides: {
      MuiTabs: {
        indicator: {
          backgroundColor: isDarkMode ? "lightgrey" : "#064778",
        },
      },

      MuiTableCell: {
        root: {
          borderBottomColor: isDarkMode ? "#223754" : "lightgrey",
          paddingBottom: 55,
        },
        head: {
          borderBottomColor: isDarkMode ? "lightgrey" : "lightgrey",
          borderBottomWidth: isDarkMode ? 1 : 3,
        },
      },
    },

    palette: {
      type: isDarkMode ? "dark" : "light",
    },
    shadows: Array(25).fill("none") as Shadows,
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { height } = useWindowDimensions();
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const toggleDarkMode = (checked: boolean) => {
    setDarkMode(checked);
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <body
        className={isDarkMode ? classes.darkMode : classes.lightMode}
        style={{ flex: 1 }}
      >
        <Grid container style={{}}>
          <LeftSideDrewer
            isDarkMode={isDarkMode}
            open={open}
            isDrawerClicked={isDrawerClicked}
            handleDrawerClose={handleDrawerClose}
          />

          <main className={classes.content}>
            <Grid container>
              <Grid
                md={isDesktop ? 9 : 12}
                container
                style={{
                  height: height,
                }}
                direction="column"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 20,
                    marginLeft: 20,
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      className={clsx(classes.menuButton, {
                        [classes.hide]: open,
                      })}
                    >
                      <MenuIcon />
                    </IconButton>
                  </div>
                  <Grid container direction="column">
                    <Typography
                      color="textPrimary"
                      style={{ fontWeight: "bold", fontSize: "3vw" }}
                    >
                      INSTRUCTIQ DATA MONITORING
                    </Typography>
                    <Typography
                      color="textSecondary"
                      style={{ fontSize: "1.3vw", opacity: 0.8 }}
                    >
                      Monitor the data stream collected by Paladin AI's
                      collector computers.
                    </Typography>
                  </Grid>
                  <div>
                    <DarkModeSwitch
                      isDarkMode={isDarkMode}
                      onChange={toggleDarkMode}
                    />
                  </div>
                </div>
                <Grid
                  container
                  direction="column"
                  style={{ paddingLeft: 92, marginTop: isDesktop ? 40 : 20 }}
                >
                  <Typography
                    color="textSecondary"
                    style={{ fontSize: "1.5vw", fontWeight: "bold" }}
                  >
                    Summary
                  </Typography>
                  <Typography
                    color="textSecondary"
                    style={{
                      fontSize: "1vw",
                      fontWeight: "lighter",
                      opacity: 0.8,
                    }}
                  >
                    July 13th - July 14th
                  </Typography>
                  <Grid container direction="row" style={{ marginTop: 20 }}>
                    {statusData.map((name, index) => (
                      <MonitorCrad
                        isDarkMode={isDarkMode}
                        status={name.status}
                        description={name.description}
                        marginLeft={index === 0 ? 0 : isDesktop ? 35 : 15}
                      />
                    ))}
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: isDesktop ? 70 : 25 }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    indicatorColor="primary"
                    style={{ paddingLeft: 72 }}
                  >
                    <Tab
                      label="Equipment A"
                      {...a11yProps(0)}
                      style={{
                        fontSize: isDesktop ? 20 : 16,
                        paddingBottom: isDesktop ? 20 : 10,
                        textAlign: "left",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    />
                    <Tab
                      label="Equipment B"
                      {...a11yProps(1)}
                      style={{
                        fontSize: isDesktop ? 20 : 16,
                        paddingBottom: isDesktop ? 20 : 10,
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    />
                    <Tab
                      label="Equipment C"
                      {...a11yProps(2)}
                      style={{
                        fontSize: isDesktop ? 20 : 16,
                        paddingBottom: isDesktop ? 20 : 10,
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    />
                  </Tabs>
                  <Divider style={{ paddingLeft: 72 }} />
                  {tabData.map((name, index) => (
                    <TabPanel
                      value={value}
                      index={index}
                      isDesktop={isDesktop}
                      isDarkMode={isDarkMode}
                      timestamp={name.lastFrame}
                      model={name.equipment}
                      rowData={response !== null ? response : null}
                    />
                  ))}
                </Grid>
                <Divider orientation="vertical" />
              </Grid>

              {isDesktop ? <RightSideBar isDarkMode={isDarkMode} /> : null}
            </Grid>
          </main>
        </Grid>
      </body>
    </ThemeProvider>
  );
};

export default MainPage;

const statusData = [
  { status: "Status A", description: "1.5%" },
  { status: "Status B", description: "8 H" },
  { status: "Status C", description: "107" },
  { status: "Collector status", description: "OK" },
];

const tabData = [
  { equipment: "B737", lastFrame: "2021-07-25 9:47:02 AM 10 mins ago" },
  { equipment: "A320", lastFrame: "2020-11-18 9:47:02 AM 40 mins ago" },
  { equipment: "B747", lastFrame: "2021-07-25 9:47:02 AM 10 mins ago" },
];
