type IdType = string | number;

export const login = () => '/';
export const mainPage = () => ('/mainPage');

// For backwards compatibility.
export default {
  mainPage,
  login,
};
