import React from "react";
import Typography from "@material-ui/core/Typography";
import { Drawer, Grid, IconButton } from "@material-ui/core";
import useWindowDimensions from "../../utils/windowsDimensions";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import paladinIcon from "../../assets/paladin-logo-b.png";
import paladinIconDark from "../../assets/paladin-logo-w.png";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import axios from "axios";
import useLocalStorage from "react-use-localstorage";

function LeftSideDrewer(props: any) {
  const {
    children,
    value,
    index,
    isDarkMode,
    open,
    isDrawerClicked,
    handleDrawerClose,
    ...other
  } = props;
  const { height, width } = useWindowDimensions();

  const [accessTokens, setAccessToken] = useLocalStorage("Access-Token");
  const accessToken = localStorage.getItem("Access-Token");
  const history = useHistory();
  const theme = createTheme();
  const updateProfile = (updatedData: any) => {
    let getAccessToken = localStorage.getItem('Access-Token')
                    
    if (getAccessToken) {
     var token = JSON.parse(getAccessToken);
    } 
   
    Object.keys(updatedData).forEach((key) => {
      token[key] = updatedData[key];
    });
    localStorage.setItem('Access-Token', JSON.stringify(token));
}
  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: 240,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: 240,
      background: isDarkMode ? "#223754" : "white",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    drawerClose: {
      background: isDarkMode ? "#223754" : "white",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
  }));

  const classes = useStyles();
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      {open && (
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
      )}
      <Grid
        container
        direction="column"
        justify="space-between"
        style={{ height: height }}
      >
        <Grid container style={{ marginTop: 20 }}>
          <Grid container justify="center">
            <img
              src={isDarkMode ? paladinIcon : paladinIconDark}
              style={{
                width: "40%",
                alignSelf: "center",
                marginTop: 15,
              }}
              alt="logo"
            />
          </Grid>
          <Grid
            container
            direction="column"
            alignContent="center"
            style={{
              height: 0.28 * height,
              justifyContent: "space-around",
              marginTop: 50,
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ViewQuiltIcon
                style={{
                  fontSize: 30,
                  color: isDarkMode ? "white" : "#223754",

                  opacity: isDrawerClicked ? 1 : 1,
                }}
              />
              {open && <Typography style={{ marginLeft: 8 }}>Home</Typography>}
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <AssessmentOutlinedIcon
                style={{
                  fontSize: 30,
                  opacity: isDrawerClicked ? 1 : 0.4,
                }}
                onClick={() => {
                  // history.push("/");
                }}
              />
              {open && (
                <Typography style={{ marginLeft: 8 }}>Charts</Typography>
              )}
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SettingsIcon
                style={{
                  fontSize: 30,
                  opacity: isDrawerClicked ? 1 : 0.4,
                }}
                onClick={() => {
                  // history.push("/");
                }}
              />
              {open && (
                <Typography style={{ marginLeft: 8 }}>Settings</Typography>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid container justify="center" style={{ marginBottom: 20 }}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <ExitToAppOutlinedIcon
              style={{ alignSelf: "center", fontSize: 30, opacity: 0.4,cursor:'pointer' }}
              onClick={() => {
                const requestConfig = {
                  headers: {
                    "x-api-key": "quu%$aq+VrE<,AvcT?*)bCh2<%#FR68wV-g'",
                  },
                };

                const requestBody = {
                  AccessToken: accessToken
                    ? JSON.parse(accessToken)
                    : accessToken,
                };
                axios
                  .post(
                    "https://development.rest-api.data-monitoring.pai-dev.io/logout",
                    requestBody,
                    requestConfig
                  )
                  .then((response) => {
                    window.localStorage.removeItem('Access-Token');
                    history.push("/");
                  })
                  .catch((error) => {
                    // console.log("error  :   ", error);
                  });
              }}
            />
            {open && <Typography style={{ marginLeft: 8 }}>Log out</Typography>}
          </div>
        </Grid>
      </Grid>
    </Drawer>
  );
}
export default LeftSideDrewer;
